@font-face {
  font-family: 'Montserrat';
  src: url('./Fonts/Montserrat-Medium.ttf') format('truetype');
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./Fonts/OpenSans-Regular.ttf') format('truetype');
}

.font-opensans {
  font-family: 'OpenSans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/****** ------- Scrollbar css ------- ****** */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(182, 182, 182, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(182, 182, 182, 0.9);
  cursor: pointer;
}


@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.slide-in-from-right {
  animation: slideInFromRight 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}